<template>
	<v-form ref="form" v-model="valid">
		<p>{{ $t('companies.contactHelp1') }}</p>
		<div class="d-flex justify-space-between">
			<v-select
				v-model="newContact"
				:items="joinedAdministrators"
				:label="$t('companies.employer')"
				item-text="name.full"
				item-value="id"
				:rules="rules.newContact"
				return-object
				required
				outlined
				rounded
				hide-details="auto"
				class="pb-3"
				style="max-width: 400px"
			/>
			<v-btn color="primary" rounded @click="callChangeContact()">
				{{ $t('settings.update') }}
			</v-btn>
		</div>
	</v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanyContactChangeForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			valid: false,
			newContact: null,
			rules: {
				newContact: [(v) => !!v || 'Contact is required.', (v) => this.joinedAdministrators.includes(v) || 'Contact must be an employer.']
			}
		}
	},
	computed: {
		joinedAdministrators() {
			return this.company.administrators.administrators.filter((admin) => admin.joined)
		},
		...mapGetters({
			company: 'companies/company'
		})
	},
	created() {
		this.newContact = this.company.administrators.administrators.filter((admin) => admin.id === this.company.contact.id)[0]
		this.loading = false
	},
	methods: {
		callChangeContact() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeContact({ companyID: this.company.id, employer: this.newContact })
		},
		...mapActions('companies', ['changeContact'])
	}
}
</script>
